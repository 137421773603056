import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: {},
    userInfo: {},
    isL: {
      isLogin: false,
      isMask: ""
    }
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setUserInfo(state, data) {
      state.userInfo = data;
    },
    setIsLogin(state, data) {
      state.isL.isLogin = data;
      state.isL.isMask = data;
    }
  },
  actions: {},
  modules: {}
});
