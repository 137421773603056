<template>
  <div class="mask" @click="closeDialog" v-if="options.isMask"></div>
</template>

<script>
export default {
  name: "CommonMask",
  props: {
    options: Object
  },
  computed: {
    isL: function() {
      return this.$store.state.isL;
    }
  },
  methods: {
    closeDialog: function() {
      this.$emit("closeDialog");
    }
  }
};
</script>

<style lang="stylus" scoped>
.mask{
    background: rgba(0, 0, 0, 0.65);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 119;
    text-align: center;
    cursor: pointer;
}
</style>
