/**
 * getUrlKey 截取地址栏参数
 * @param value
 * @returns {String}
 */
function getUrlKey(name) {
  return (
    decodeURIComponent(
      (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
        location.href
      ) || ["", ""])[1].replace(/\+/g, "%20")
    ) || null
  );
}

export default {
  getUrlKey
};
