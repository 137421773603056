<template>
  <div class="home">
    <user-login :options="options"></user-login>
    <loading :options="options"></loading>
    <common-mask :options="options" @closeDialog="closeDialog"></common-mask>
  </div>
</template>

<script>
import Loading from "./Loading";
import CommonMask from "./CommonMask";
import UserLogin from "./UserLogin";
export default {
  name: "Home",
  inject: ["reload"],
  components: {
    Loading,
    CommonMask,
    UserLogin
  },
  data() {
    return {
      options: {
        isLogin: true,
        isMask: false,
        isLoading: false
      },
      platFormType: "",
      gamesList: [],
      isFbDialog: false
    };
  },
  mounted() {
    this.thirdLogin();
  },
  methods: {
    getGamesList: function() {
      let datas = datas;
      this.getHttp(this, datas, "/front/game/all", function(obj, data) {
        if (data.status == 2000) {
          console.log("此时所有游戏列表", data.data);
          obj.gamesList = data.data;
          obj.firstGameId = data.data[0].id;
        }
        obj.options.isLoading = false;
      });
    },
    thirdLogin: function() {
      this.account = this.$utils.getUrlKey("account");
      this.name = this.$utils.getUrlKey("name");
      this.token = this.$utils.getUrlKey("token");
      this.type = this.$utils.getUrlKey("type");
      this.signature = this.$utils.getUrlKey("signature");
      if (this.account && this.name && this.token && this.type) {
        this.options.isLoading = true;
        let datas = {
          account: this.account,
          name: this.name,
          token: this.token,
          type: this.type //facebook/google/apple
        };
        this.postHttp(this, datas, "/front/others/login", function(obj, data) {
          if (data.status == 2000) {
            obj.options.isHmsLogin = false;
            obj.options.isLoading = false;
            obj.options.isMask = false;
            obj.$toast(data.message);
            let token = data.data.bear_token;
            let userInfo = data.data.user_info;
            obj.$store.commit("setToken", token);
            obj.$store.commit("setUserInfo", userInfo);
            localStorage.setItem("token", token);
            localStorage.setItem("userInfo", JSON.stringify(userInfo));
            obj.options.isLoading = true;
            obj.$router.push({ path: "/payment" }).catch(err => err);
            // obj.reload();
          } else {
            obj.$toast(data.message);
            obj.options.isLoading = false;
          }
        });
      } else {
        this.options.isLoading = false;
      }
    },
    closeDialog: function() {
      this.options.isMask = false;
      //   this.isFbDialog = false;
    }
  }
};
</script>
<style lang="stylus"></style>
