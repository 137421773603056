<template>
  <div class="login_wrapper login" v-cloak>
    <div
      class="login_box login_in animated bounceIn"
      :class="{ bounceOut: isAni }"
    >
      <form class="form">
        <dl>
          <div class="con">
            <div>
              <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
                <div class="userInput">
                  <el-form-item prop="userName">
                    <div>
                      <dd class="userName">
                        <el-input
                          placeholder="帳號"
                          class="inp"
                          v-model="ruleForm.userName"
                        ></el-input>
                      </dd>
                    </div>
                  </el-form-item>

                  <el-form-item prop="passWord">
                    <div>
                      <div class="clear"></div>
                      <dd class="passWord">
                        <el-input
                          type="password"
                          placeholder="密碼"
                          class="inp"
                          v-model="ruleForm.passWord"
                          @keyup.enter.native="submitForm('ruleForm')"
                        ></el-input>
                      </dd>
                    </div>
                  </el-form-item>
                </div>
                <div class="clear"></div>

                <el-form-item>
                  <dd class="border">
                    <el-button
                      type="primary"
                      class="btn_login"
                      @click="submitForm('ruleForm')"
                      >立即登入</el-button
                    >
                  </dd>
                </el-form-item>

                <el-form-item>
                  <dd class="icon">
                    <span>遊戲帳號登錄</span>
                    <div class="gg" @click="thirdLogin('google')"></div>
                    <div class="mar15 tw" @click="thirdLogin('apple')"></div>
                    <div class="mar15 fb" @click="thirdLogin('facebook')"></div>
                  </dd>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </dl>
      </form>
    </div>
    <loading :options="options"></loading>
    <vue-particles color="#ccc" :particlesNumber="40"></vue-particles>
  </div>
</template>

<script>
import Loading from "./Loading";
import "../assets/styles/login.css";
// import "@/pages/pc/assets/styles/login.css";打包加载不出来
export default {
  name: "UserLogin",
  inject: ["reload"],
  components: {
    Loading
  },
  data: function() {
    return {
      isAni: false,
      account: "",
      passWord: "",
      isRemember: "",
      checked: true,
      options: {
        isLoading: false
      },
      ruleForm: {
        userName: "",
        passWord: ""
      },
      rules: {
        userName: [
          { required: true, message: "請輸入帳號", trigger: "blur" },
          {
            min: 6,
            max: 18,
            message: "用户名长度在6-18位字符",
            trigger: "blur"
          }
        ],
        passWord: [
          { required: true, message: "請輸入密码", trigger: "blur" },
          {
            min: 6,
            max: 18,
            message: "密码长度在6-18位字符",
            trigger: "blur"
          }
        ]
      }
    };
  },
  computed: {
    isL: function() {
      return this.$store.state.isL;
    }
  },
  mounted() {},
  methods: {
    handleRegister: function() {
      this.$emit("handleRegister");
    },
    handleFindPassword: function() {
      this.$emit("handleFindPassword");
      setTimeout(() => {
        this.$bus.emit("stepInit", 1);
      }, 200);
    },
    thirdLogin: function(type) {
      this.options.isLoading = true;
      this.platFormType = type;
      this.toLink();
    },
    toLink: function() {
      this.options.isLoading = true;
      let appid = "asgkpz";
      //第三方登录跳转
      // 正式服
      if (this.platFormType == "facebook") {
        this.url =
          "https://sdk.gamemorefun.com/mobile/v1/passport/facebook/5?app_id=" +
          appid +
          "&redirect_url=https%3A%2F%2Fwww.gamemorefun.com%2Flogin%2F%23%2F";
      }

      if (this.platFormType == "google") {
        this.url =
          "https://sdk.gamemorefun.com/rest/v2/passport/google/5?app_id=" +
          appid +
          "&redirect_url=https%3A%2F%2Fwww.gamemorefun.com%2Flogin%2F%23%2F";
      }

      if (this.platFormType == "apple") {
        this.url =
          "https://sdk.gamemorefun.com/rest/v2/passport/apple/5?app_id=" +
          appid +
          "&redirect_url=https%3A%2F%2Fwww.gamemorefun.com%2Flogin%2F%23%2F";
      }

      // 测试服
      //   if (this.platFormType == "facebook") {
      //     this.url =
      //       "https://sdk.gamemorefun.com/mobile/v1/passport/facebook/5?app_id=" +
      //       appid +
      //       "&redirect_url=http%3A%2F%2Fdevmore.morefun.zone%2Flogin%2F%23%2F";
      //   }

      //   if (this.platFormType == "google") {
      //     this.url =
      //       "https://sdk.gamemorefun.com/rest/v2/passport/google/5?app_id=" +
      //       appid +
      //       "&redirect_url=http%3A%2F%2Fdevmore.morefun.zone%2Flogin%2F%23%2F";
      //   }

      //   if (this.platFormType == "apple") {
      //     this.url =
      //       "https://sdk.gamemorefun.com/rest/v2/passport/apple/5?app_id=" +
      //       appid +
      //       "&redirect_url=http%3A%2F%2Fdevmore.morefun.zone%2Flogin%2F%23%2F";
      //   }
      window.location.href = this.url;
    },
    submitForm: function(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.morefunLogin(this.ruleForm.userName, this.ruleForm.passWord);
        } else {
          console.log("錯誤的提交 !!");
          return false;
        }
      });
    },
    morefunLogin: function(userName, passWord) {
      this.options.isLoading = true;
      let datas = {
        account: userName,
        password: passWord
      };
      this.postHttp(this, datas, "/front/login", function(obj, data) {
        if (data.status == 2000) {
          obj.options.isLoading = false;
          obj.$toast(data.message);
          obj.$store.commit("setIsLogin", false);
          obj.options.isLogin = false;
          obj.options.isLoginin = true;
          obj.options.isLoginSuccess = true;
          let token = data.data.bear_token;
          let userInfo = data.data.user_info;
          obj.$store.commit("setToken", token);
          obj.$store.commit("setUserInfo", userInfo);
          localStorage.setItem("token", token);
          localStorage.setItem("userInfo", JSON.stringify(userInfo)); //保存登录状态
          obj.$router.push({ path: "/payment" }).catch(err => err);
          // obj.reload();
          obj.options.isLoading = false;
        } else {
          obj.options.isLoading = false;
          obj.options.isLogin = false;
          obj.options.isSign = false;
          obj.options.isMask = false;
          obj.$toast(data.message);
        }
      });
    }
  }
};
</script>

<style lang="stylus" scoped>
.v-enter, .v-enter-to {
    opacity: 0;
}

.v-enter-active, .v-leave-active {
    transition: opacity 1s;
}

.login >>> .el-input__inner {
    text-indent: 42px;
}

.login >>> .el-form-item__error {
    color: #F56C6C;
    top: 65%;
    left: 17%;
}

.login{
    /* background: #ebebeb; */
    //background-image: linear-gradient(to bottom, #ebebeb, #507af3);
    /* background-image: linear-gradient(to bottom, #c88068, #7b81cb, #507af3); */
    background-image:url('../assets/imgs/bg.jpg');
}

.login_wrapper {
	position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    min-height: 100%;
    overflow: hidden;

    .btn_login, .el-form-item {
        margin-bottom: 0 !important;
    }

    .forgetPassword {
        input[name='checkbox'] {
            margin-top: 12px !important;
            margin-right: 9px !important;
        }
    }

    .login_box {
        width: 407px;
        height: 440px;
        right: -23%;
        top: 50%;
        margin-top: -370.5px;
        text-align: center;
        background: url('../assets/imgs/login_bg.png')no-repeat center;
        background-size: 100% 100%;

        .login_close {
            position: absolute;
            width: 89px;
            height: 82px;
            right: 0;
            top: 0;
            border-top-right-radius: 30%;
            background: url('../assets/imgs/close.png') no-repeat center;
            background-size: 100% 100%;
            cursor: pointer;

            i {
                display: inline-block;
                width: 31.67px;
                height: 5.65px;
                position: absolute;
                right: 9px;
                top: 18px;
                background: #ffffff;
                border-radius: 2.5px;
            }
        }

        .logo {
            position: relative;
            display: inline-block;
            width: 95px;
            height: 95px;
            margin-top: 15px;
            // background: #f0e0cd;
            border-radius: 100%;

            i {
                display: inline-block;
                width: 70px;
                height: 51px;
                position: absolute;
                left: 50%;
                top: 50%;
                margin-left: -35px;
                margin-top: -25.5px;
                background: #ffffff;
                border-radius: 2.5px;
                background: url('../assets/imgs/game-icon.png') no-repeat center;
                background-size: 500px 500px;
                background-position: 0 -104px;
            }
        }

        .icon {
            span {
                display: inline-block;
                color: #545454;
                font-size: 14px;
                vertical-align: top;
                margin-right: 25px;
                margin-top: 8px;
            }

            div {
                display: inline-block;
                width: 39px;
                height: 46px;
                margin-top: 8px;
                background: url('../assets/imgs/game-icon.png') no-repeat center;
                background-size: 500px 500px;
                cursor: pointer;
                &.gg {
                    background-position: 0 -166px;
                }

                &.tw {
                    background-position: -61px -166px;
                }

                &.fb {
                    background-position: -123px -166px;
                }
            }
        }

        .login_reg {
            a {
                color: #2c2c2c;
                font-size: 14px;
                text-decoration: none;
            }
        }

        .userInput {
            width: 310px;
            height: 103px;
            margin: 0 auto;
            padding: 0 10px;
            margin-top: 95px;
            border: 2px solid #ffcf96;
            border-radius: 10px;
            background: #fff;

            dd {
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    z-index: 1;
                    left: 18px;
                    top: 16px;
                    display: inline-block;
                    width: 21px;
                    height: 21px;
                    background: url('../assets/imgs/game-icon.png') no-repeat center;
                    background-size: 500px 500px;
                }

                &.userName {
                    border-bottom: 1px solid #dcbc9a;
                    overflow: hidden;

                    &::before {
                        background-position: 0 -213px;
                    }
                }

                &.passWord {
                    &::before {
                        background-position: 0 -243px;
                    }
                }
            }

            .el-input__inner {
                text-indent: 47px;
                text-align: left;
            }

            .el-input {
                display: inherit;
            }
        }
    }
}
</style>
