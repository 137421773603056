<template>
  <div class="loading" v-if="options.isLoading">
    <img src="../assets/imgs/loading.gif" alt />
  </div>
</template>

<script>
export default {
  name: "Loading",
  props: {
    options: Object
  },
  data() {
    return {};
  }
};
</script>
<style scoped>
.loading {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 130;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.loading img {
  width: 60px;
  height: 24px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 0 !important;
  margin-top: -12px !important;
  margin-left: -30px !important;
}
</style>
